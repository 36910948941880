<template>
  <a-modal :visible="visible" title="添加Banner" destroyOnClose :maskClosable="false"  @ok="handleOk()" @cancel="handleCancel">
    <a-form
      ref="form"
      :model="formState"
      :validateOnRuleChange="false"
      name="form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
      autocomplete="off"
      scrollToFirstError
    >
      <a-form-item
        label="店名"
        name="name"
      >
        <a-input v-model:value="formState.name" name="name" />
      </a-form-item>
      <a-form-item
        label="城市"
        name="city"
      >
        <a-input v-model:value="formState.city" name="city" />
      </a-form-item>
      <a-form-item
        label="地址"
        name="address"
      >
        <a-input v-model:value="formState.address" name="address" />
      </a-form-item>
      <a-form-item
        label="经纬度"
        name="location"
      >
        <a-input v-model:value="formState.location" name="location" />
      </a-form-item>
      <a-form-item
        label="封面图片"
        name="coverImageId"
      >
        <GigaUpload v-model:value="formState.coverImageId" :imageUrl="formState.coverImageUrl"  name="coverImageId" @setImg="setImg($event, 'coverImage')" />
      </a-form-item>
      <a-form-item
        label="联系手机号"
        name="phoneNum"
      >
        <a-input v-model:value="formState.phoneNum" name="phoneNum" />
      </a-form-item>
      <a-form-item
        label="联系二维码"
        name="wechatQrcode"
      >
        <a-input v-model:value="formState.wechatQrcode" name="wechatQrcode" />
      </a-form-item>
      <a-form-item
        label="标题(筹备中填写)"
        name="title"
      >
        <a-input v-model:value="formState.title" name="title" />
      </a-form-item>
      <a-form-item
        label="描述(筹备中填写)"
        name="description"
      >
        <a-input v-model:value="formState.description" name="description" />
      </a-form-item>
      <a-form-item
        label="车间图片"
        name="images"
      >
        <GigaUpload v-model:value="formState.images" name="images" :images="formState.images || []" :multiple="true" @sortImg="formState.images = $event" @setImg="setImg($event, 'images', 'multiple')" @removeImg="removeImg($event, 'images')"  />
      </a-form-item>
      <a-form-item
        label="排序"
        name="displayOrder"
      >
        <a-input v-model:value="formState.displayOrder" name="displayOrder" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { ref, watch } from 'vue';
import GigaUpload from '../GigaUpload.vue'
export default {
  name: 'BannerForm',
  components: {
    GigaUpload
  },
  props: ['visible', 'formData'],
  setup(props) {
    const formState = ref(props.formData)
    watch(() => props.formData, (newVal) => formState.value = newVal)
    return {
      formState,
      form: ref(null),
    };
  },
  
  methods: {
    setImg(e, param, multiple) {
      console.log(e)
      let img = e[0]
      let formState = this.formState
      if (multiple) {
        if (!formState[param] || !Array.isArray(formState[param])) formState[param] = []
        formState[param] = formState[param].concat(img)
      } else {
        formState[`${param}Id`] = img.id
        formState[`${param}Url`] = img.url
      }
      console.log(formState)
      this.formState = JSON.parse(JSON.stringify(formState))
    },
    removeImg(id, param) {
      console.log(id)
      let formState = this.formState
      let images = formState[param]
      images.forEach((e, index) => {
        if (e.id == id) {
          images.splice(index, 1)
        }
      })
      formState[param] = images
      this.formState = JSON.parse(JSON.stringify(formState))
    },
    handleOk() {
      this.form.validateFields().then(res => {
        res.displayOrder = res.displayOrder ? parseInt(res.displayOrder) : 0
        let formState = Object.assign(this.formState, res)
        formState.imageIds = formState.images ? formState.images.map(e => e.id) : []
        if (formState.location) {
          formState.location = {
            latitude: formState.location.split(',')[0] || '',
            longitude: formState.location.split(',')[1] || ''
          }
        }
        this.$emit('formOk', formState)
      }).catch(err => {
        console.error(err)
        this.$message.error(err.errorFields[0].errors[0])
      })
    },
    handleCancel() {
      this.$emit('formCancel')
    },
  },

}
</script>